<template>
  <skills id="skills"/>
</template>

<script>
import Skills from '@/components/Skills.vue';

export default {
  components: {
    Skills,
  },
  name: 'AppFooter',
};
</script>
