<template>
  <div>
    <h5 id="about">A little about my self</h5>
    <p>I'm a Java Developer at AfriGIS,
      a Geographic Information Systems company based in Pretoria. I studied National
      Diploma: Computer Systems at the University of Johannesburg. Majoring in Database design,
      systems design and software development
    </p>
    <p>I'm passionate about software development. I'm always constantly looking forward to
      extending my skills and build my knowledge further in this industry.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Summary',
};
</script>

<style>

</style>
